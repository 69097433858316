import React from 'react'

import { Link } from 'react-router-dom'

function Header(){

    return(
        <div className="header">
            <Link to="/" className="lien-header">
                {/* <NameContainer className={"texte-bold"}>
                    <Exposant className={"texte-regular"}>MD</Exposant><Name>STUDIO DE CRÉATION</Name>
                </NameContainer> */}
                <img className="logo rotate" alt="MD | Studio / Création" src="/images/logo.svg"/>
            </Link>
            <Link to="/infos" className="lien-header lien-contact">
                <div className="rotate">
                    <span className="texte-thin">infos & contacts</span>
                </div>
            </Link>
        </div>
    )
}

export default Header
import React from 'react';
import ReactDOM from 'react-dom';

import './assets/Absara/absara.css'
import './assets/Absara/AbsaraSans-Regular.otf'
import './assets/Absara/AbsaraSansTF-BoldSC.otf'
import './assets/Absara/AbsaraSansTF-LightSC.otf'
import './assets/Absara/AbsaraSansTF-MediumSC.otf'

import './assets/Alegreya/AlegreyaSans-Thin.otf'
import './assets/Alegreya/AlegreyaSans-ThinItalic.otf'
import './assets/Alegreya/AlegreyaSans-Light.otf'
import './assets/Alegreya/AlegreyaSans-LightItalic.otf'
import './assets/Alegreya/AlegreyaSans-Regular.otf'
import './assets/Alegreya/AlegreyaSans-Italic.otf'
import './assets/Alegreya/AlegreyaSans-Medium.otf'
import './assets/Alegreya/AlegreyaSans-MediumItalic.otf'
import './assets/Alegreya/AlegreyaSans-Bold.otf'
import './assets/Alegreya/AlegreyaSans-BoldItalic.otf'
import './assets/Alegreya/AlegreyaSans-ExtraBold.otf'
import './assets/Alegreya/AlegreyaSans-ExtraBoldItalic.otf'
import './assets/Alegreya/AlegreyaSans-Black.otf'
import './assets/Alegreya/AlegreyaSans-BlackItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-Thin.otf'
import './assets/Alegreya/AlegreyaSansSC-ThinItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-Light.otf'
import './assets/Alegreya/AlegreyaSansSC-LightItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-Regular.otf'
import './assets/Alegreya/AlegreyaSansSC-Italic.otf'
import './assets/Alegreya/AlegreyaSansSC-Medium.otf'
import './assets/Alegreya/AlegreyaSansSC-MediumItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-Bold.otf'
import './assets/Alegreya/AlegreyaSansSC-BoldItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-ExtraBold.otf'
import './assets/Alegreya/AlegreyaSansSC-ExtraBoldItalic.otf'
import './assets/Alegreya/AlegreyaSansSC-Black.otf'
import './assets/Alegreya/AlegreyaSansSC-BlackItalic.otf'
import './assets/Alegreya/alegreya.css'

import './index.css'

import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState } from 'react'

import Panel from './Panel'
import Container from './Container'

function Infos(props){
    const [donnees, setDonnees] = useState(null)

    let references = null
    let infos = null

    if(!donnees){
        let request = new XMLHttpRequest()
        request.open("GET","donnees.json",false)
        request.send(null)
        setDonnees(JSON.parse(request.responseText))
        
        return null
    }
    else if(donnees.infos_contact){
        references = Object.keys(donnees.infos_contact.references).map((ref,key)=>{
            if(ref === "titre") return null
            const refs = donnees.infos_contact.references[ref].map((val,k)=><span className="contact-detail" key={k}>{val}</span>)
            
            return(<div className="contact-block" key={key}>
                    <h3 className="texte-regular contact-soustitre">{ref}</h3>
                    {refs}
                </div>)
        })
        infos = donnees.infos_contact.infos.map((ref,key)=><p className="contact-paragraph" key={key}>{ref}</p>)
    }

    const reseaux = Object.keys(donnees.infos_contact.contact.reseaux).map((val,key)=>{
        if(val === "titre") return null
        return <a key={key} href={donnees.infos_contact.contact.reseaux[val]} target="_blank" rel="noopener noreferrer" style={{color:"black"}}><span className="contact-detail">{val}</span></a>
    })

    let shop = null

    if(donnees.infos_contact.contact.shop){
        shop = Object.keys(donnees.infos_contact.contact.shop).map((val,key)=>{
            if(val === "titre") return null
            return <a key={key} href={donnees.infos_contact.contact.shop[val]} target="_blank" rel="noopener noreferrer" style={{color:"black"}}><span className="contact-detail">{val}</span></a>
        })
    }   
    
    let soustitre = null

    if(donnees.infos_contact.contact.soustitre){
        soustitre = <h3 className="texte-medium contact-titre">{donnees.infos_contact.contact.soustitre}</h3>
    }

    const contact = (
        <div className="texte-thin contact-wrap">
            <div className="contact-block">
                <h3 className="texte-medium contact-titre">{donnees.infos_contact.contact.titre}</h3>
                {soustitre}
                <span className="contact-detail">{donnees.infos_contact.contact.lieu}</span>
                <a href={"mailto:"+donnees.infos_contact.contact.mail} style={{color:"black",textDecoration:"none"}}><span className="contact-detail">{donnees.infos_contact.contact.mail}</span></a>
                <span className="contact-detail">{donnees.infos_contact.contact.telephone}</span>
            </div>

            <div className="contact-block">
                <h3 className="texte-medium contact-titre">{donnees.infos_contact.contact.reseaux.titre}</h3>
                {reseaux}
            </div>
            
            {shop ? (<div className="contact-block">
                <h3 className="texte-medium contact-titre">{donnees.infos_contact.contact.shop.titre}</h3>
                {shop}
            </div>): null}
            
            <div className="contact-block">
                <h3 className="texte-medium contact-titre">{donnees.infos_contact.references.titre}</h3>
                {references}
            </div>

            <div className="contact-block">
                <h3 className="texte-medium contact-titre">Crédits & design</h3>
                <span className="contact-detail">© Mathilde Dubois</span>
            </div>

            <div className="contact-block">
                <h3 className="texte-medium contact-titre">Développement</h3>
                <span className="contact-detail"><a href="https://www.linkedin.com/in/victor-ronfaut-1a5303157/" target="_blank" rel="noopener noreferrer" style={{color:"black"}}>Victor Ronfaut</a></span>
            </div>
        </div>
    )
    return(
        <Container>
            <Panel left>
                <div className="nodisplaybigscreen">
                    <div className="contact-photo border-bot">
                        <img className="photo" alt="Studio création" src="/images/contact.jpg"/>
                    </div>
                </div>   
                <div className="texte-thin contact-wrap">
                    {infos}
                </div>
                <div className="nodisplaybigscreen">
                    {contact}
                </div>
                <div className="contact-photo border-top">
                    <img className="photo" alt="Mathilde Dubois" src="./images/portrait.jpg"/>
                </div>
            </Panel>
            <Panel>
                <div className="contact-photo border-bot">
                    <img className="photo" alt="Studio création" src="/images/contact.jpg"/>
                </div>
                {contact}
            </Panel>
        </Container>
    )
}

export default Infos
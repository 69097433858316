import React from 'react'
import { Link } from 'react-router-dom'
import flechegauche from '../assets/flechegauche.png'
import flechedroite from '../assets/flechedroite.png'
import rond from '../assets/rond.png'

const NeighborsProjects = ({left,right}) => {
    const leftCpt = left ? (
    <Link to={`/projet/${left}`} style={{color:'black'}}>
        <div className="conteneur-fleche">
            <img className="fleche" alt="projet précédent" src={flechegauche}/>
        </div>
    </Link>
    ):(   
    <Link to={''} onClick={event => event.preventDefault()} style={{cursor: 'default',color:'black'}}>
        <div className="conteneur-fleche noexist">
            <img className="fleche" alt="projet précédent" src={flechegauche}/>
        </div>
    </Link>
    )
    const rightCpt = right ? (
    <Link to={`/projet/${right}`} style={{color:'black'}}>
        <div className="conteneur-fleche">
            <img className="fleche" alt="projet suivant" src={flechedroite}/>
        </div>
    </Link>
    ):(    
    <Link to={''} onClick={event => event.preventDefault()} style={{cursor: 'default', color:'black'}}>
        <div className="conteneur-fleche noexist">
            <img className="fleche" alt="projet suivant" src={flechedroite}/>
        </div>
    </Link>
    )

    return(
        <div className="nav-conteneur">
            <div className="conteneur-navproj">
                {leftCpt}        
                {rightCpt}
            </div>
            <Link to={'/'}>
                <div className="conteneur-rond">
                    <img className="rond" alt="accueil" src={rond}/>
                </div>
            </Link>
        </div>
    )
}

export default NeighborsProjects
import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import Panel from './Panel'
import Container from './Container'


function Home(){
    const [donnees, setDonnees] = useState(null)

    if(!donnees){
        let request = new XMLHttpRequest()
        request.open("GET","donnees.json",false)
        request.send(null)
        setDonnees(JSON.parse(request.responseText))
    }

    let photosaccueil = null
    if(donnees)
    {
        photosaccueil = Object.keys(donnees.projects).map((key) => {
            return(
            <div key={key} className="conteneur-photo">
                <Link className="lien-photo" key={key} to={`/projet/${key}`}>
                    <img className="photo" alt={`projet ${key}`} src={`images/accueil/${key}01.jpg`}/>
                </Link>
            </div>
        )})
    }

    return(
        <Container>
            <Panel left>
                {photosaccueil}
            </Panel>
            <Panel odd>
                {photosaccueil}
            </Panel>
        </Container>
    )
}

export default Home
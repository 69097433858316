import React from 'react'
import ImageLoader from 'react-load-image'
import fusee from '../assets/fusee.png'

function Image(props){
    return(<ImageLoader src={props.src}>
        <img className="photo" alt="projet"/>
        <div className="projet-loader">
            <span>Erreur !</span>
        </div>
        <div className="projet-loader">
            <img src={fusee} className="fusee" style={{height:'20%'}} alt='Un peu de patience, ça charge !'/> 
        </div>  
    </ImageLoader>)
}

export default Image
import React, { useState, useEffect, useRef } from 'react'
import NeighborsProjects from './NeighborsProjects'
import Image from './Image'
import { Redirect } from 'react-router-dom'

function Projet({ match }){ 
    const [donnees, setDonnees] = useState(null)

    useEffect(() => {
        let request = new XMLHttpRequest()
        try{
            request.open("GET","/donnees.json",false)
            request.send(null)
            const tmp = JSON.parse(request.responseText)
            const tmpidx = Object.keys(tmp.projects).indexOf(match.params.project)
            if(tmpidx>=0){
                let res = tmp.projects[match.params.project]
                res.left = Object.keys(tmp.projects)[tmpidx-1]
                res.right = Object.keys(tmp.projects)[tmpidx+1]
                setDonnees(res)
            }
            else{
                setDonnees("nomatch")
            }
        }catch(e){
            console.log(e)
        }   
    },[match.params.project]);

    if(!donnees){        
        return <div className="projet-conteneur"></div>
    }
    else{
        if(donnees === "nomatch"){
            return <Redirect to="/"/>
        }
        let details = Object.keys(donnees).map((value,key)=>{
            if(value !== "commentaire" && value !== "photos" && value !== "name" && value !== "left" && value !== "right")
            return(<div className="projet-info" key={key}>
                <h3 className="texte-regular projet-soustitre">{value}</h3>
                <span className="projet-valeur">{donnees[value]}</span>
                </div>)
            else return null
        })

        const images = donnees.photos.map((value,key)=>{
            const tmp = value.split('.')
            if(tmp[tmp.length-1] === "mp4"){
                return(<div className="conteneur-photo projet" key={key}>
                    <div className="photo" controls>
                        <source src={value} type="video/mp4"/>
                    </div>
                </div>)
            }
            else{
                return(<div className="conteneur-photo projet" key={key}>
                    <Image className="photo" src={value}/>
                </div>)
            }
        })

        return (<div className="projet-conteneur">
            <div className="texte-thin projet-wrap">
                <h2 className={"texte-medium projet-titre"}>{donnees.name}</h2>  
                {details}
                <p className="projet-paragraph">
                {donnees.commentaire}
                </p>
                <NeighborsProjects left={donnees.left} right={donnees.right}/>
            </div>
            <PhotoWrap>
                <div className="texte-thin projet-wrap head">
                    <h1 className="texte-medium projet-titre">{donnees.name}</h1>                
                    {details}
                    <p className="texte-courant projet-paragraph">
                    {donnees.commentaire}
                    </p>
                    <NeighborsProjects left={donnees.left} right={donnees.right}/>
                </div>
                {images}
            </PhotoWrap>
        </div>)
    }    
}

function PhotoWrap(props){
    const [ypos,setYpos] =  useState(0)
    const [justUpdated, setJustUpdated] = useState(false)
    const containerRef = useRef(null)
    
    const scrollHandler = (e) => {
        if(!justUpdated){
            setJustUpdated(true)
            setTimeout(() => {setJustUpdated(false)}, 20)
            let tmp = window.innerHeight/5
            if(e.deltaY < 0) tmp = -tmp 
            let newPos = ypos + tmp
            if(newPos > containerRef.current.scrollHeight-containerRef.current.offsetHeight) 
                newPos = containerRef.current.scrollHeight-containerRef.current.offsetHeight
            else if(newPos < 0) newPos = 0
            containerRef.current.scroll(0,newPos)
            setYpos(newPos)
        }
    }

    return(
        <div className="panneau right projet" ref={containerRef} onWheel={null/*scrollHandler*/}>
                {props.children}
        </div>
    )
}

export default Projet
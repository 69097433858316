import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import styled from 'styled-components'

import Header from './components/Header'
import Home from './components/Home'
import Infos from './components/Infos'
import Projet from './components/Projet'


function App() {
  return (
    <Container>
      <BrowserRouter>
        <Header/>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path="/infos" component={Infos}/>
          <Route path="/projet/:project" component={Projet}/>
          <Redirect to="/"/>
        </Switch>
      </BrowserRouter>
    </Container>
  );
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 100%;

  @media(max-width: 980px){
    font-size: 60%
  }
`

export default App;

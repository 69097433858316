import React, { useState, useRef } from 'react';

function Panel(props){
    const [ypos,setYpos] =  useState(0)
    const [justUpdated, setJustUpdated] = useState(false)
    const containerRef = useRef(null)
    
    const scrollHandler = (e) => {
        if(!justUpdated){
            setJustUpdated(true)
            setTimeout(() => {setJustUpdated(false)}, 20)
            let tmp = window.innerHeight/5
            if(e.deltaY < 0) tmp = -tmp 
            let newPos = ypos + tmp
            if(newPos > containerRef.current.scrollHeight-containerRef.current.offsetHeight) 
                newPos = containerRef.current.scrollHeight-containerRef.current.offsetHeight
            else if(newPos < 0) newPos = 0
            containerRef.current.scroll(0,newPos)
            setYpos(newPos)
        }
    }
    const isLeft = props.left ? "left":"right"
    const isOdd = props.odd? "odd":"even"

    return(
        <div className={`panneau ${isLeft} ${isOdd}`} ref={containerRef}>
                {props.children}
        </div>
    )
}

export default Panel